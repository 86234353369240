import React, { useState, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import useEffectLocation from "../hooks/useEffectLocation";

import "./style.scss";

const Scrollbar = React.lazy(() => import("smooth-scrollbar"));

const Demo3 = React.lazy(() => import("../views/home/Demo3"));
const ComingSoon = React.lazy(() => import("../views/home/ComingSoon"));
const FourOhFour = React.lazy(() => import("../components/404"));

const Work = React.lazy(() => import("../views/Work"));
const ProjectDetails = React.lazy(() =>
  import("../views/portfolio/ProjectDetails")
);

const About = React.lazy(() => import("../views/About"));
const Contact = React.lazy(() => import("../views/Contact"));
const BlogDetails = React.lazy(() => import("../views/blog/BlogDetails"));

const Router = () => {
  const [transPage, setTransPage] = useState("in");
  const scrollbar: React.MutableRefObject<null | Scrollbar> = useSelector(
    (state) => state.scrollbar
  );

  const location = useEffectLocation((l) => {
    setTransPage("out");
  });

  const onAnimateEnd = () => {
    if (transPage !== "out") return;
    scrollbar.current?.scrollTo(0, 0, 0);
    window.scrollTo({ left: 0, top: 0 });
    setTransPage("in");
  };

  return (
    <div
      id="dsn-content"
      className={`dsn-transition-page dsn-animate-${transPage}`}
      onAnimationEnd={onAnimateEnd}
    >
      <Suspense fallback={<div className="background-main h-100-v" />}>
        <Routes location={location}>
          {/* Routes */}
          {/* <Route path="*" element={<ComingSoon />} /> */}
          <Route path="/" element={<Demo3 />} />
          <Route exact path="/portfolio" element={<Work />} />
          <Route exact path="/portfolio/:slug" element={<ProjectDetails />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="*" element={<FourOhFour />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default React.memo(Router);
