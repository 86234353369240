import React from "react";
import { dsnCN } from "../../../hooks/helper";

function MenuContent({ className }) {
  const socialData = [
    { link: "https://www.instagram.com/jorismolenaar_/", name: "Instagram." },
    { link: "https://www.facebook.com/JorisMolenaarMedia/", name: "Facebook." },
    { link: "https://www.linkedin.com/in/joris-molenaar/", name: "Linkedin." },
  ];
  return (
    <div
      className={dsnCN(
        "container-content  d-flex flex-column justify-content-center",
        className
      )}
    >
      <div className="nav__info">
        <div className="nav-content">
          <h5 className="sm-title-block mb-10">Contact</h5>
          <p className="links over-hidden mb-1">
            <a
              className="link-hover"
              href="tel:+31 6 37338892"
              data-hover-text="+31 6 37338892"
            >
              +31 6 37338892
            </a>
          </p>
          <p className="links over-hidden">
            <a
              className="link-hover"
              href="mailto:info@jorismedia.com"
              data-hover-text="info@jorismedia.com"
            >
              info@jorismedia.com
            </a>
          </p>
        </div>
      </div>
      <div className="nav-social nav-content mt-30">
        <div className="nav-social-inner p-relative">
          <h5 className="sm-title-block mb-10">Volg mij</h5>
          <ul>
            {socialData.map((item, index) => (
              <li key={index}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MenuContent;
